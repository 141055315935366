import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
// import TextFieldGroup from "../../components/common/TextFieldGroup";
import IconTextFieldGroup from "../../components/common/IconTextFieldGroup";
// import SubmitInput from "../../components/common/SubmitInput";

import { Button, Col, Form, Row } from "reactstrap";
import { USER_HOME_PATH, ADMIN_HOME_PATH } from "../../config/api-config";
import ContentBox from "../../components/common/ContentBox";

class Login extends Component {
	constructor() {
		super();
		this.state = {
			email: "",
			password: "",
			errors: {}
		};

		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {}

	componentWillReceiveProps(nextProps) {
		if (nextProps.auth.isAuthenticated) {
			if (nextProps.auth.user.isAdmin) {
				this.props.history.push(ADMIN_HOME_PATH);
			} else {
				this.props.history.push(USER_HOME_PATH);
			}
		}

		if (nextProps.errors) {
			this.setState({ errors: nextProps.errors });
		}
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}
	onSubmit(e) {
		e.preventDefault();

		const userData = {
			email: this.state.email,
			password: this.state.password
		};

		this.props.loginUser(userData);
	}
	render() {
		const { errors } = this.state;
		return (
			<div style={{ justifyContent:'center',margin: '0 auto', width: '100%', maxWidth: '720px' }}>
			<ContentBox width="6" title="Login">
				<Form noValidate onSubmit={this.onSubmit}>
					<p className="text-muted">Log in to your account</p>

					<IconTextFieldGroup
						label="Email Address"
						placeholder="Email Address"
						name="email"
						type="email"
						value={this.state.email}
						onChange={this.onChange}
						error={errors.email || errors.error}
						icon="user"
					/>

					<IconTextFieldGroup
						className="mb-4"
						label="Password"
						placeholder="Password"
						name="password"
						type="password"
						value={this.state.password}
						onChange={this.onChange}
						error={errors.password}
						icon="lock"
					/>

					<Row>
						{errors.user ? (
							<p className="invalid-feedback pt-1 pb-2" style={{ display: "unset " }}>
								{errors.user}
							</p>
						) : null}
					</Row>
					<Row>
						<Col xs="6">
							<Button color="primary" className="px-4" id="login">
								Login
							</Button>
						</Col>
						<Col xs="6" className="text-right">
							<Link className="px-0" to="/login/forgot-password">
								Forgot password?
							</Link>
						</Col>
					</Row>
				</Form>
			</ContentBox>
			</div>
		);
	}
}

Login.propTypes = {
	loginUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	errors: state.errors
});

export default connect(
	mapStateToProps,
	{ loginUser }
)(Login);
