import React from "react";
// import Loadable from 'react-loadable'

import AdminRoute from "./components/common/AdminRoute";
import UserRoute from "./components/common/UserRoute";

import Login from "./container/login/Login";
import Register from "./container/register/Register";
import CreateProject from "./container/createProject/CreateProject";
import Dashboard from "./container/dashboard/Dashboard";
import Projects from "./container/projects/Projects";
import SerialNumberSearch from "./container/dashboard/SerialNumberSearch";
import UserProfile from "./container/userProfile/UserProfile";
import SingleProject from "./container/singleProject/SingleProject";
import EditProfile from "./container/editProfile/EditProfile";
import ProjectDrafts from "./container/projectDrafts/ProjectDrafts";
import ForgotPassword from "./container/forgotPassword/ForgotPassword";
import ResetPassword from "./container/resetPassword/ResetPassword";
import Admin from "./container/admin/Admin";
import Settings from "./container/admin/Settings";
import NotFound from "./container/notFound/NotFound";
import Project from "./container/admin/Project";
import MyProjects from "./container/project/MyProjects";
import RegisterComplete from "./container/register/RegisterComplete";
import About from "./container/about/About";
import EmissionTest from "./components/emissionReduction/EmisionTest";
import AdminProjects from "./container/admin/AdminProjects";
import AdminUsers from "./container/admin/AdminUsers";
import AdminRequests from "./container/admin/AdminRequests";
import Contact from "./container/contact/Contact";
import { ProjectHistory } from "./container/project/ProjectHistory";
import Terms from "./container/terms/terms";
import overview from "./container/overview/overview";
import InventoryOverview from "./container/overview/InventoryOverview";
import { ProgramTypeEnum } from "./lib/Enums";
import PaymentContact from "./components/projects/PaymentContact";
import TestEmail from "./container/test/TestEmail";
import TestDeleteEmail from "./container/test/TestDeleteEmail";
import microsite from "./container/microsite/microsite";
import ProjectsSeekingFunding from "./container/ProjectsSeekingFunding/ProjectsSeekingFunding";
import {HowItWorks, FAQ} from "./container/support/FAQ.js";


// Helper function to reduce code duplication
function AdminOnly(comp) {
	return () => <AdminRoute component={comp} />;
}

function UserOnly(comp, extraProps) {
	return () => <UserRoute component={comp} extraProps={extraProps} />;
}

// Todo: potential future optimization

// function Loading() {
//   return <div>Loading...</div>;
// }

// const Login = Loadable({
//   loader: () => import('./container/login/Login'),
//   loading: Loading,
// });

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
	{ path: "/", exact: true, name: "Home", component: Dashboard },
	{ path: "/index.html", exact: true, name: "Home", component: Dashboard },
	{ path: "/about", exact: true, name: "About", component: About },
	{ path: "/contact", exact: true, name: "Contact", component: Contact },
	{ path: "/howitworks", exact: true, name: "How it Works", component: HowItWorks },
	{ path: "/faq", exact: true, name: "FAQ", component: UserOnly(FAQ) },
	{ path: "/test", exact: true, name: "Test", component: EmissionTest },
	{ path: "/login", exact: true, name: "Login", component: Login },
	{
		path: "/login/forgot-password",
		exact: true,
		name: "Fortgot Password",
		component: ForgotPassword
	},
	{ path: "/reset-password", exact: true, name: "Reset Password", component: ResetPassword },
	{ path: "/register", exact: true, name: "Register", component: Register },
	{
		path: "/register/complete",
		exact: true,
		name: "Registraion Complete",
		component: RegisterComplete
	},
	{ path: "/projects", exact: true, name: "Projects", component: Projects },
	{ path: "/serial", exact: true, name: "Serial Number", component: SerialNumberSearch },
	{ path: "/projects/:id", exact: true, name: "Project", component: SingleProject },
	{ path: "/overview/:id", exact: true, name: "Accountholder Overview", component: overview },
	{ path: "/seeking-funding", exact: true, name: "Projects Seeking Funding", component: ProjectsSeekingFunding },

	{ path: "/profile", exact: true, name: "Profile", component: UserOnly(UserProfile) },
	{ path: "/myprojects", exact: true, name: "My Projects", component: UserOnly(MyProjects) },
	{ path: "/overview", exact: true, name: "Overview", component: UserOnly(overview) },
	{
		path: "/inventory/projects",
		exact: true,
		name: "Inventory Projects",
		component: UserOnly(MyProjects, { inventory: true })
	},
	{
		path: "/offsets/projects",
		exact: true,
		name: "Offset Projects",
		component: UserOnly(MyProjects, { offsets: true })
	},
	{
		path: "/inventory/overview",
		exact: true,
		name: "GHG True-up Overview",
		component: UserOnly(InventoryOverview)
	},
	{
		path: "/inventory/overview/:id",
		exact: true,
		name: "GHG True-up Overview",
		component: InventoryOverview
	},
	{
		path: "/inventory/create",
		exact: true,
		name: "Create Inventory",
		component: UserOnly(CreateProject, { programType: "Inventory" })
	},
	{
		path: "/inventory/create-offsets",
		exact: true,
		name: "Create Offsets",
		component: UserOnly(CreateProject, { programType: ProgramTypeEnum.PurchasedOffsets })
	},
	{
		path: "/project/pay",
		exact: true,
		name: "Pay for Projects",
		component: UserOnly(PaymentContact)
	},
	{
		path: "/inventory/pay",
		exact: true,
		name: "Pay for Inventory",
		component: UserOnly(PaymentContact, { inventory: true })
	},
	{
		path: "/terms-and-conditions",
		exact: true,
		name: "Terms And Conditions",
		component: UserOnly(Terms)
	},
	{ path: "/profile/edit", exact: true, name: "Edit Profile", component: UserOnly(EditProfile) },
	{ path: "/profile/drafts", exact: true, name: "Profile", component: UserOnly(ProjectDrafts) },
	{
		path: "/create-project",
		exact: true,
		name: "Create Project",
		component: UserOnly(CreateProject)
	},
	{
		path: "/profile/drafts/:id",
		exact: true,
		name: "Profile",
		component: UserOnly(CreateProject)
	},

	{ path: "/admin", exact: true, name: "Admin", component: AdminOnly(Admin) },
	{ path: "/admin/settings", exact: true, name: "Settings", component: AdminOnly(Settings) },
	{ path: "/admin/project/:id", exact: true, name: "Project", component: AdminOnly(Project) },
	{ path: "/admin/projects", exact: true, name: "Projects", component: AdminOnly(AdminProjects) },
	{ path: "/admin/users", exact: true, name: "Users", component: AdminOnly(AdminUsers) },
	{ path: "/admin/requests", exact: true, name: "Requests", component: AdminOnly(AdminRequests) },
	{
		path: "/projects/history/:id",
		exact: true,
		name: "Project",
		component: AdminOnly(ProjectHistory)
	},

	{ path: "/test/email", exact: true, name: "Test", component: TestEmail },
	{ path: "/test/delete-email", exact: true, name: "Test", component: TestDeleteEmail },
	
	{ path: "/:id", exact: true, name: "Organization", component: microsite },

	{ path: "*", exact: true, name: "Not Found", component: NotFound }
];

export default routes;
