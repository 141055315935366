import React, { Component } from "react";
import { Link } from "react-router-dom";

class ProjectItem extends Component {
	render() {
		const { project } = this.props;
		const imageUrl = project.pictures.featured.url
			? project.pictures.featured.url + ".300.jpg"
			: "";
		return (
			// <Link to={`/projects/${project.projectSerial}`}>
			// 	<div
			// 		style={{ width: "100%", paddingBottom: "75%", background: "gray", margin:5 }}
			// 	>
			// 		<div
			// 			style={{
			// 				position: "absolute",
			// 				// top: 5,
			// 				// bottom: 5,
			// 				// left: 5,
			// 				// right: 5,
			// 				overflow: "hidden",
			// 				background: "gray"
			// 			}}
			// 		>
			// 			<img
			// 				className="d-block"
			// 				src={project.pictures.featured.url && project.pictures.featured.url+".300.jpg"}
			// 				alt={project.title}
			// 				style={{
			// 					margin: "auto",
			// 					// marginLeft: "auto",
			// 					// marginRight: "auto",
			// 					//maxHeight: "100%",
			// 					width: "100%",
			// 					borderRadius:5
			// 				}}
			// 			/>
			// 			<div style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
			// 				<div
			// 					//captionText={item.caption}
			// 					//captionHeader=
			// 					style={{
			// 						background: "#75994C",
			// 						color: "white",
			// 						textAlign: "center"
			// 					}}
			// 					className="custom-tag d-block"
			// 				>
			// 					<p className={"projects-text"}>{project.title}</p>
			// 				</div>
			// 			</div>
			// 		</div>
			// 	</div>
			// </Link>
			<Link to={`/projects/${project.projectSerial}`} style={{ textDecoration: "none", display: "flex", justifyContent: "center" }}>
				<div
					style={{
						backgroundColor: "#75994C",
						borderRadius: "4px",
						overflow: "hidden",
						boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
						maxHeight: "260px",
						display: "flex",
						flexDirection: "column",
						height: "100%",
						borderTop: "10px solid #75994C",
						borderRight: "10px solid #75994C",
						borderLeft: "10px solid #75994C",
						maxWidth: "285px",
					}}
				>
					<div style={{ height: "180px", overflow: "hidden" }}>
						<img
							src={imageUrl}
							alt={project.title}
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
								display: "block",
							}}
						/>
					</div>
					<div
						style={{
							background: "#75994C",
							color: "white",
							textAlign: "center",
							padding: "12px 8px",
							flexGrow: 1,
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<p className="projects-text" style={{ margin: 0 }}>{project.title}</p>
					</div>
				</div>
			</Link>

			// <div className="container-fluid" style={style}>
			// 	{project.pictures.featured.url ? (
			// 		<div>
			// 			<div className="imgContainer2" /*style={style.img}*/>
			// 				<img src={project.pictures.featured.url} alt="" />
			// 			</div>
			// 		</div>
			// 	) : null}
			// 	<div className="projectMeta pt-3 pb-3">
			// 		{project.status === 1 ? (
			// 			<Link to={`/profile/drafts/${project._id}`}>Edit Draft</Link>
			// 		) : (
			// 			<Link to={`/projects/${project._id}`}>{project.title}</Link>
			// 		)}
			// 	</div>
			// </div>
		);
	}
}
export default ProjectItem;
