import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getPublicProjects } from "../../actions/projectActions";
import classnames from "classnames";

import {
	Row,
	Col,
	Button,
	Input,
	Container,
	InputGroup,
	FormGroup,
	NavItem,
	Nav,
	NavLink,
	TabPane,
	TabContent,
} from "reactstrap";

import ContentBox from "../../components/common/ContentBox";
import ProjectsMap from "../../components/ProjectsMap";
import { ProgramTypeEnum } from "../../lib/Enums";
// import CarouselSlide from "./ProjectsCarousel";

class Dashboard extends Component {
	constructor() {
		super();
		this.state = {
			filterType: "all",
			searchField: "",
			openMarker: null,
			openTab: "1",
		};

		this.filterClick = this.filterClick.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	toggle(tab) {
		if (this.state.openTab !== tab) this.setState({ openTab: tab });
	}

	componentDidMount() {
		//this.props.getFeaturedProjects();
		//this.props.getProjects();
		this.props.getPublicProjects();
	}

	filterClick(e) {
		this.setState({ filterType: e.target.getAttribute("value") });
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onSubmit(e) {
		e.preventDefault();
		this.props.getSearchProjects(this.state.filterType, this.state.searchField);
	}

	render() {
		const { loading, publicProjects } = this.props.project;

		const ready = publicProjects && !loading;

		var entities = {};
		if (ready) {
			for (var px in publicProjects) {
				const p = publicProjects[px];

				if (!entities[p.organization]) {
					entities[p.organization] = {
						organizationLogo: p.organizationLogo,
						microsite: p.organizationSite,
						organizationSerial: p.organizationSerial,
						used: false,
					};
				}

				if (p.programType !== ProgramTypeEnum.Inventory) {
					entities[p.organization].used = true;
				}
			}
		}

		const proposedProjects =
			ready &&
			publicProjects.filter(
				(x) => x.projectStatus === "Proposed" && x.visibility === "Public",
			);
		return (
			<React.Fragment>
				<div
					className="full-width d-flex pb-3 header1 d-flex"
					style={{
						backgroundColor: "#F9F9F9",
					}}
				>
					<Container>
						<div
							className="full-width"
							style={{
								backgroundColor: "#F9F9F9",
							}}
						>
							<Container
								className="header6 pt-5"
								style={{
									alignItems: "center",
									maxHeight: "100%",
								}}
							>
								{/*<Container className="planet-mobile">*/}
								{/*	<img*/}
								{/*		src="/planet.svg"*/}
								{/*		alt=""*/}
								{/*		className="mx-auto mb-4 mt-5  planet-mobile"*/}
								{/*	/>*/}
								{/*</Container>*/}

								<Container className={'lcr-header'}>
									<h2
										style={{
											color: "#952168",
										}}
										className="mb-5 ml-2 align-middle headerStyle"
									>
										Showcase, track, and learn from <u>climate action</u>{" "}
										projects in your community
									</h2>
										<Button
											tag={Link}
											to="/register/"
											color="primary"
											className="mt-3"
										>
											Join today
										</Button>
								</Container>
								<Container>
									<img src="/planet.svg" alt="" className="mx-auto mb-4 planet" />
								</Container>
							</Container>
						</div>
					</Container>
				</div>
				<div className="full-width pt-3 pb-5">
					<Container
						className="header2"
						style={{
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<div className={'show-on-tablet'}>
							<div className="ml-1 pt-4" style={{ maxWidth: 612, textAlign: "justify" }}>
								<p className="subtitle">
									Plan your actions based on where you are and where you could go.
								</p>
								<p className="paragraph">
									The Local Carbon Registry promotes recognition, transparency,
									and credibility for community climate action projects. Project
									owners can showcase their greenhouse gas emission reduction
									projects, track emission reductions associated with a project,
									and share knowledge on best practices.
								</p>
							</div>
						</div>
						<div>
							<img
								style={{
									justifyContent: "center",
									display: "flex",
								}}
								src="/hands.svg"
								alt=""
								className="mx-auto my-1 hands"
							/>
						</div>
						<div className={'show-on-mobile'}>
							<div className="ml-1" style={{ maxWidth: 612, textAlign: "justify" }}>
								<p className="subtitle">
									Plan your actions based on where you are and where you could go.
								</p>
								<p className="paragraph">
									The Local Carbon Registry promotes recognition, transparency,
									and credibility for community climate action projects. Project
									owners can showcase their greenhouse gas emission reduction
									projects, track emission reductions associated with a project,
									and share knowledge on best practices.
								</p>
							</div>
						</div>
					</Container>
				</div>

				<div
					className="full-width py-5 header3"
					style={{
						backgroundColor: "#F9F9F9",
					}}
				>
					<Container>
						<div
							style={{
								alignItems: "center",
							}}
							className=" pt-4 pb-4 py-5 align-me"
						>
							<div className="mapTitle pb-5">Registry Projects</div>
							<Container>
								<div className="pb-5">
									<div>
										<Row>
											<Col md="12">
												<div>
													<Nav tabs>
														<NavItem>
															<NavLink
																className={classnames({
																	active:
																		this.state.openTab === "1",
																})}
																onClick={() => {
																	this.toggle("1");
																}}
															>
																Registry Projects
															</NavLink>
														</NavItem>
														<NavItem>
															<NavLink
																className={classnames({
																	active:
																		this.state.openTab === "2",
																})}
																onClick={() => {
																	this.toggle("2");
																}}
															>
																Seeking Funding
															</NavLink>
														</NavItem>
													</Nav>
													<TabContent activeTab={this.state.openTab}>
														<TabPane tabId="1" className={'map-dashboard-container'}>
															<ProjectsMap
																projects={
																	ready &&
																	publicProjects.filter(
																		(x) =>
																			x.visibility ===
																			"Public" &&
																			x.projectStatus !==
																			"Proposed",
																	)
																}
																fitMap={false}
															/>
														</TabPane>
														<TabPane tabId="2">
															<ProjectsMap
																projects={proposedProjects}
																fitMap={false}
															/>
														</TabPane>
													</TabContent>
												</div>
											</Col>
											<Col md="12" className="pt-5">
												<FormGroup>
													<InputGroup>
														<img
															src="/search.svg"
															alt=""
															className="mx-auto my-4 search"
														/>
														<Input
															className="searchBox"
															style={{
																borderRadius: "5px",
																border: "1px solid #1D84B5",
																color: "red",
																height: "41px",
																paddingLeft: "37px",
															}}
															id="inputID"
															placeholder="Search by Offset Serial Number..."
															name="searchField"
															onChange={this.onChange}
															onKeyDown={(e) => {
																if (e.key === "Enter") {
																	this.props.history.push(
																		`/serial/?serial=${this.state.searchField}`,
																	);
																}
															}}
														/>
														<Button
															color="secondary"
															tag={Link}
															to="/projects/"
															className="btn-show-all mb-1 ml-3"
														>
															See All Projects
														</Button>
													</InputGroup>
												</FormGroup>
											</Col>
										</Row>
									</div>
								</div>
							</Container>
						</div>
					</Container>
				</div>
				<div
					className="full-width"
					style={{
						backgroundColor: "#F9F9F9",
					}}
				>
					{/*<CarouselSlide />*/}
				</div>
				<div className="full-width">
					<Container
						className="header4 py-5"
						style={{
							alignItems: "center",
							justifyContent:'center',
						}}
					>
						<div className={'left-element'}>
							<div style={{ maxWidth: 612 }} className="header-text-block">
								<p className="subtitle">
									The first integrated platform for managing{" "}
									<u>community-based</u> emission reduction projects
								</p>
								<p className="paragraph">
									The Local Carbon Registry is powerful because it is the first
									carbon registry designed to facilitate community-owned emission
									reductions projects rather than corporate-owned projects. By
									encouraging local climate action, the Local Carbon Registry
									supports circular economies and community development.
								</p>
									<Button
										tag={Link}
										to="/register/"
										color="primary"
										className="mt-4"
									>
										Know More
									</Button>
							</div>
						</div>
						<div>
							<img
								style={{
									justifyContent: "center",
									display: "flex",
								}}
								src="/volunteering.svg"
								alt=""
								className="mx-auto my-4 volunteering"
							/>
						</div>
					</Container>
				</div>

				<div
					className="full-width"
					style={{
						backgroundColor: "#F9F9F9",
					}}
				>
					<Container
						className="header5 pt-5"
						style={{
							alignItems: "center",
							justifyContent:'center',
						}}
					>
						<div className={'left-element'}>
							<div className="ml-1" style={{ maxWidth: 612, textAlign: "justify" }}>
								<p className="subtitle-blue">PROJECTS</p>
								<p className="paragraph">
									Register local emission reduction projects to make real changes
									happen in your community. Showcase your success stories, provide
									comprehensive information to document the achievements and to
									share lessons learned.
								</p>
							</div>
						</div>
						<div>
							<img
								style={{
									justifyContent: "center",
									display: "flex",
								}}
								src="/sharing.svg"
								alt=""
								className="mx-auto mb-4 volunteering"
							/>
						</div>
					</Container>
				</div>

				<div
					className="full-width"
					style={{
						backgroundColor: "#F9F9F9",
					}}
				>
					<Container
						className="header6 pt-5"
						style={{
							alignItems: "center",
							justifyContent:'center',
						}}
					>
						<div className={'left-element'}>
							<img src="/computer.svg" alt="" className="mx-auto mb-4 computer" />
						</div>
						<div>
							<div className="ml-1 lcr-header" style={{ maxWidth: 612}}>
								<p className="subtitle-blue">GHG INVENTORY</p>
								<p className="paragraph">
									Setup and track your GHG inventory over time.Account for your
									impact as well as your progress.Plan and realize your GHG
									reduction journey.
								</p>
									<Button
										tag={Link}
										to="/register/"
										color="primary"
										className="mt-4"
									>
										START YOUR JOURNEY
									</Button>
							</div>
						</div>
						<div className="computer-mobile">
							<img
								src="/computer.svg"
								alt=""
								className="mx-auto mb-4 mt-5  computer-mobile"
							/>
						</div>
					</Container>
				</div>

				<ContentBox title="All Participating Entities">
					<Row
						style={{
							backgroundColor: "white",
							borderRadius: "5px",
							marginTop: "-15px",
							marginBottom: "-15px",
							paddingTop: "5px",
						}}
					>
						{Object.keys(entities).map((x) => {
							const cont = (
								<figure>
									<img
										src={entities[x].organizationLogo || "/img.png"}
										alt=""
										style={{ maxHeight: 80, maxWidth: 150 }}
										className="dashboard-entity"
									/>
									<figcaption className="dashboard-entitycaption">{x}</figcaption>
								</figure>
							);

							return (
								<Col lg="2" md="3" xs="6" key={x}>
									{entities[x].used ? (
										<Link
											to={
												entities[x].microsite > 0
													? `/${x.toLowerCase().replace(/\s/g, "")}`
													: `/${entities[x].organizationSerial}`
											}
										>
											{cont}
										</Link>
									) : (
										cont
									)}
								</Col>
							);
						})}
					</Row>
					<Col md="12" className="mt-2">
						<div
							style={{
								textAlign: "center",
								marginTop: "auto",
							}}
							className="mb-2 "
						>
							<Button
								tag={Link}
								to="/register/"
								color="tertiary"
								className="mt-3 joinButton"
							>
								Join today and be part of the solution
							</Button>
						</div>
					</Col>
				</ContentBox>
			</React.Fragment>
		);
	}
}

Dashboard.propTypes = {
	project: PropTypes.object.isRequired,
	dashboard: PropTypes.object,
};

const mapStateToProps = (state) => ({
	project: state.project,
	dashboard: state.dashboard,
});

export default connect(mapStateToProps, { getPublicProjects })(Dashboard);
