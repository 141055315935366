import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import axios from "axios";
import { registerUser } from "../../actions/authActions";
import TextFieldGroup from "../../components/common/TextFieldGroup";
import TextAreaFieldGroup from "../../components/common/TextAreaFieldGroup";
// import SubmitInput from "../../components/common/SubmitInput";
import { Button, Col, Row } from "reactstrap";
import { USER_HOME_PATH } from "../../config/api-config";
import ContentBox from "../../components/common/ContentBox";
import SmallSpinner from "../../components/common/SmallSpinner";

class Register extends Component {
	constructor() {
		super();
		this.state = {
			organization: "",
			name: "",
			email: "",
			password: "",
			password2: "",

			inventoryTonnes: "0",
			projectCount: "0",
			comment: "",

			errors: {},
		};

		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		if (this.props.auth.isAuthenticated) {
			this.props.history.push(USER_HOME_PATH);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.errors) {
			this.setState({ errors: nextProps.errors });
		}
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onSubmit(e) {
		e.preventDefault();

		const newUser = {
			organization: this.state.organization,
			name: this.state.name,
			email: this.state.email,
			password: this.state.password,
			password2: this.state.password2,

			projects: this.state.projectCount,
			inventory: this.state.inventoryTonnes,
			comment: this.state.comment,
		};

		this.props.registerUser(newUser, this.props.history);
	}
	render() {
		const { errors } = this.state;
		return (
			<div style={{ justifyContent:'center',margin: '0 auto', width: '100%', maxWidth: '720px' }}>
			<ContentBox width="6" title="Create an Account">
				<p>
					Already have an account? <Link to="/login">Sign in</Link>
				</p>

				<form noValidate onSubmit={this.onSubmit}>
					<TextFieldGroup
						label="Organization"
						placeholder=""
						name="organization"
						type="text"
						value={this.state.organization}
						onChange={this.onChange}
						error={errors.organization}
					/>
					<TextFieldGroup
						label="Contact Person"
						placeholder=""
						name="name"
						type="text"
						value={this.state.name}
						onChange={this.onChange}
						error={errors.name}
					/>
					<TextFieldGroup
						label="Email"
						placeholder=""
						name="email"
						type="email"
						value={this.state.email}
						onChange={this.onChange}
						error={errors.email}
					/>
					<TextFieldGroup
						label="Password"
						placeholder=""
						name="password"
						type="password"
						value={this.state.password}
						onChange={this.onChange}
						error={errors.password}
					/>
					<TextFieldGroup
						label="Confirm Password"
						placeholder=""
						name="password2"
						type="password"
						value={this.state.password2}
						onChange={this.onChange}
						error={errors.password2}
					/>

					<p>
						The fee for registering your emission reduction project on the Local Carbon
						Registry is $500 plus GST per project. The fee goes towards the general
						maintenance and administration of the registry. It will also help us
						continue to add enhancements to the registry and develop new initiatives to
						promote local carbon reductions.
					</p>

					<TextFieldGroup
						label="How many project do you want to register? Type 0 if you don't want to use projects."
						placeholder=""
						name="projectCount"
						type="text"
						value={this.state.projectCount}
						onChange={(e) => this.onChange(e)}
						error={errors.projects}
					/>

					<p>
						In addition to emission project database, the registry provides inventory
						and offsets functionalities, which allow clients to enter their corporate
						inventory and purchased offsets and track their carbon neutrality. The
						<i>GHG True-up Overview</i> functionality is equipped with visual analytics
						that will provide insights to emission reduction strategy. There is a
						one-time access fee to unlock these functionalities.
					</p>

					<TextFieldGroup
						id="inventory"
						label="What is your organization’s most recent inventory (in t CO2e)? Type 0 if you don't want to use inventory functionalities."
						placeholder=""
						name="inventoryTonnes"
						type="text"
						value={this.state.inventoryTonnes}
						onChange={(e) => this.onChange(e)}
						error={errors.inventory}
					/>

					<TextAreaFieldGroup
						id="comment"
						label="Additional Comments"
						placeholder=""
						name="comment"
						type="text"
						value={this.state.comment}
						onChange={(e) => this.onChange(e)}
						// error={errors.name}
					/>

					<Row>
						<Col xs="6">
							<Button
								id="register"
								disabled={this.props.auth.working}
								color="primary"
								className="px-4"
							>
								<SmallSpinner width="20px" show={this.props.auth.working} />
								Register
							</Button>
						</Col>
					</Row>
				</form>
			</ContentBox>
			</div>
		);
	}
}

Register.propTypes = {
	registerUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
	errors: state.errors,
});

export default connect(mapStateToProps, { registerUser })(withRouter(Register));
