import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import { getProject } from "../../actions/projectActions";
import { getPublicProjects } from "../../actions/projectActions";
import ContentBox from "../../components/common/ContentBox";
import NotFound from "../notFound/NotFound";
import Carousel from "../../components/common/Carousel";
import { Row, Col, ListGroup } from "reactstrap";
import EmissionReductionView from "../../components/emissionReduction/EmissionReductionView";
import { CombineEmissions, StatusEnum } from "../../lib/Emissions";
import { add } from "../../lib/Helpers";
import { Link } from "react-router-dom";
import { Map, Marker, TileLayer } from "react-leaflet";
import { OverviewGraph } from "../overview/OverviewGraph";
import { getEmissionsArray } from "../../lib/Emissions";
import { ProgramTypeEnum } from "../../lib/Enums";

class SingleProject extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		this.props.getPublicProjects();
		this.checkForChangedProject({ project: { publicProjects: undefined } });
	}

	onErChange(newData) {
		this.setState({ emissionReductions: newData });
	}

	checkForChangedProject(prevProps) {
		if (
			this.props.project.publicProjects &&
			prevProps.project.publicProjects !== this.props.project.publicProjects
		) {
			const project = this.getProject();
			if (project) {
				this.setState({ emissionReductions: project.emissionReductions });
			}
		}
	}

	componentDidUpdate(prevProps) {
		this.checkForChangedProject(prevProps);
	}

	getProject() {
		const { publicProjects } = this.props.project;
		const id = this.props.match.params.id;
		return publicProjects.find((x) => x.projectSerial === id);
	}

	render() {
		const { publicProjects } = this.props.project;
		if (!publicProjects) {
			return <div className={'mt-5'}>Loading...</div>;
		}

		const project = this.getProject();

		if (!project) {
			return <NotFound />;
		}

		const isInventory = project.programType === ProgramTypeEnum.Inventory;
		const isOffsets = project.programType === ProgramTypeEnum.PurchasedOffsets;
		const isInventoryOrOffsets = isInventory || isOffsets;
		const fieldPrefix = isInventoryOrOffsets ? "" : "Project ";

		const lat = project.location.latitude;
		const lng = project.location.longitude;

		const snapshot = [
			["Account Holder", project.organization],
			project.offsetProvider && ["Offsets Provider", project.offsetProvider],
			!isOffsets && ["Program Type", project.programType],
			!isOffsets && ["Project Type", project.projectType],
			!isOffsets && [
				"Project Stage",
				project.projectStatus === "Proposed" ? "Proposed - Seeking Funding" : project.projectStatus,
			],
			[
				fieldPrefix + "Timeline",
				project.timeline.startDate + " - " + project.timeline.endDate,
			],
			[
				!isOffsets ? "Emission Reductions" : "Purchased Offsets",
				CombineEmissions(project.emissionReductions)
					.map((x) => (x.state === StatusEnum.Cancelled ? 0 : parseInt(x.count) || 0))
					.reduce(add, 0) + " tonnes CO₂ e",
			],
			[fieldPrefix + "ID", project.projectSerial],
			project.location.address && ["Location", project.location.address],
			lat && lng && ["Latitude / Longitude", lat + " / " + lng],
			project.contact.fullName && [
				"Project Contact",
				<React.Fragment>
					{project.contact.fullName + ", " + project.contact.jobTitle} <br />
					<Link
						to={`/contact?projectId=${project.projectSerial}&name=${project.contact.fullName}`}
					>
						Send Message
					</Link>
				</React.Fragment>,
			],
		].filter((x) => x);

		// show pictures in defined order
		const images = [
			{ src: project.pictures.featured.url, caption: "Featured" },
			{ src: project.pictures.projectSite.url, caption: "Project Site" },
			{ src: project.pictures.before.url, caption: "Before" },
			{ src: project.pictures.activity.url, caption: "After" },
			{ src: project.pictures.otherImage.url, caption: "" },
		].filter((x) => x.src);

		const documents = [
			{ src: project.documents.projectPlan, caption: "Project Plan" },
			{ src: project.documents.financialPlan, caption: "Financial Plan" },
			{ src: project.documents.projectValidation, caption: "Project Validation" },
			{ src: project.documents.verificationReport, caption: "Verification Report" },
			{ src: project.documents.other1, caption: "Other Document" },
			{ src: project.documents.other2, caption: "Other Document" },
		].filter((x) => x.src && x.src.url);

		var projectNames = {};
		Array.isArray(this.props.project.publicProjects) &&
			this.props.project.publicProjects.forEach((x) => {
				projectNames[x.projectSerial] = x.title;
			});

		return (
			// TODO conditionaly render project.title and description. currently gives 404 if no projects.
			<ContentBox
				title={
					<React.Fragment>
						<div className={'project-title-wrap'}>
							{project.organizationLogo ? (
								<p className="mb-0">
									<img
										className="mr-2 project-logo-avatar"
										src={project.organizationLogo}
										alt=""
									/>{" "}
								</p>
							) : project.organization ? (
								project.organization + ": "
							) : (
								""
							)}
							{project.title}
						</div>
					</React.Fragment>
				}
				width="12"
			>
				{/* <h1>{project.title}</h1> */}

				<Row>
					{lat && lng && (
						<Col md={{ size: 6 }} className=" mb-3">
							{/* style={{ width: "100%", paddingBottom: "75%" }} */}
							<div>
								<Map
									className="public-project-map"
									center={[parseFloat(lat) || 0, parseFloat(lng) || 0]}
									zoom={9}
									//zoomControl={false}
									scrollWheelZoom={false}
									boxZoom={false}
									keyboard={false}
									dragging={false}
									//touchZoom={false}
									doubleClickZoom={false}
								>
									<TileLayer
										url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
										attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>'
									/>

									<Marker
										position={{
											lat: parseFloat(lat) || 0,
											lng: parseFloat(lng) || 0,
										}}
									/>
								</Map>
							</div>
						</Col>
					)}
					{images.length > 0 && (
						<Col md={{ size: 6 }}>
							{/* Fixed aspect ratio outer container to have decent layout */}
							<div style={{ width: "100%", paddingBottom: "10%" }}>
								<Carousel items={images} showCaption={false} />
							</div>
						</Col>
					)}
				</Row>

				<Row className="pl-0">
					{project.description && (
						<Col md="12">
							<h2 className="mb-4">{fieldPrefix}Description</h2>
							<div>
								{project.description.split("\n").map((item, i) => (
									<p key={i} style={{ textAlign: "justify" }}>
										{item}
									</p>
								))}
							</div>
						</Col>
					)}
					<Col md="6">
						<h2 className="mb-4">{fieldPrefix}Snapshot</h2>
						<div className="bd-example">
							<dl className="row">
								{snapshot.map((item, idx) => (
									<React.Fragment key={idx}>
										<dt className="col-sm-6">{item[0]}</dt>
										<dd className="col-sm-6">{item[1]}</dd>
									</React.Fragment>
								))}
							</dl>
						</div>
					</Col>
					{documents.length > 0 && (
						<Col md="6">
							<h2 className="mb-4">{fieldPrefix}Documents</h2>
							<ListGroup>
								<div className="bd-example">
									<dl className="row">
										{documents.map((item) => (
											<React.Fragment key={item.caption}>
												<dt className="col-sm-4 pr-0">{item.caption}</dt>
												<dd className="col-sm-8 text-nowrap text-truncate">
													<a
														href={item.src.url}
														target="_blank"
														rel="noopener noreferrer"
													>
														{item.src.filename}
													</a>
												</dd>
											</React.Fragment>
										))}
									</dl>
								</div>
							</ListGroup>
						</Col>
					)}

					<Col md={{ size: 12 }}>
						<h2 className="mb-4">
							{isOffsets ? "Purchased Offsets" : "Emission Reductions"}
						</h2>
						<div>
							<Col lg="10" className="pb-4">
								<OverviewGraph
									height="250px"
									emissionArray={getEmissionsArray(
										[project],
										true /*includeDraftProjects*/,
										true /* Fill Gaps */,
									)}
								/>
							</Col>
						</div>
						{this.state.emissionReductions && (
							<EmissionReductionView
								value={{
									registered: this.state.emissionReductions.registered,
									draft: this.state.emissionReductions.draft.map((x) =>
										x.status === StatusEnum.RetiredInventory
											? {
													...x,
													status: StatusEnum.Retired,
													retiredAgainst: "Inventory-" + x.year,
											  }
											: x
									),
								}}
								projectNames={projectNames}
								onChange={(data) => this.onErChange(data)}
							/>
						)}
					</Col>
				</Row>
			</ContentBox>
		);
	}
}

SingleProject.propTypes = {
	getPublicProjects: PropTypes.func.isRequired,
	project: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	project: state.project,
});

export default connect(mapStateToProps, { getPublicProjects })(SingleProject);
