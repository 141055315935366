import React, {Component} from "react";
import {Row, Col, Container} from "reactstrap";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Spinner from "../../components/common/Spinner";
import {getPublicProjects} from "../../actions/projectActions";
import ProjectItem from "../../components/projects/ProjectItem";
import ContentBox from "../../components/common/ContentBox";
import qs from "querystring";

class Projects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: "",
            currentPage: 1,
            projectsPerPage: 12
        };
    }

    componentDidMount() {
        this.props.getPublicProjects();
    }

    render() {
        const {publicProjects, loading} = this.props.project;
        let projectItems;
        var projects = publicProjects && publicProjects.filter(x => x.visibility === "Public");

        if (!projects || loading) {
            return <Spinner/>;
        }

        const params = qs.parse(this.props.location.search.slice(1)); //{ ignoreQueryPrefix: true }

        if (params.serial) {
            projects = projects.filter(x =>
                x.projectSerial.startsWith(params.serial.substring(0, 12))
            );
        }

        const filteredProjects = projects.filter((project) =>
            Object.values(project).some((value) =>
                typeof value === "string" &&
                value.toLowerCase().includes(this.state.searchTerm.toLowerCase())
            )
        );

        const {currentPage, projectsPerPage} = this.state;
        const indexOfLastItem = currentPage * projectsPerPage;
        const indexOfFirstItem = indexOfLastItem - projectsPerPage;
        const currentProjects = filteredProjects.slice(indexOfFirstItem, indexOfLastItem);

        projectItems = filteredProjects.length > 0
            ? filteredProjects.map(project => (
                <Col md="4" sm="6" key={project.projectSerial}
                     style={{marginBottom: "40px", paddingLeft: "10px", paddingRight: "10px"}}>
                    <ProjectItem project={project}/>
                </Col>
            ))
            : <h4>No Projects found...</h4>;


        projectItems = currentProjects.length > 0
            ? currentProjects.map(project => (
                <Col md="4" sm="6" key={project.projectSerial}
                     style={{marginBottom: "40px", paddingLeft: "10px", paddingRight: "10px"}}>
                    <ProjectItem project={project}/>
                </Col>
            ))
            : <h4>No Projects found...</h4>;

        return (
            <>
                {/* HEADER */}
                <div
                    className="full-width"
                    style={{
                        backgroundColor: "#F9F9F9",
                        position: "relative",
                        padding: '0 0 60px 0'
                    }}
                >
                    <Container
                        className="header-projects-main py-5"
                        style={{
                            alignItems: "center",
                            justifyContent: 'center',
                        }}
                    >
                        <div className={'left-element'}>
                            <div style={{maxWidth: 612, textAlign: "justify"}}>
                                <p className="subtitle">
                                    Registered Projects
                                </p>
                                <p className="paragraph">
                                    Register local emission reduction projects to make real changes happen in your
                                    community. Showcase your success stories, provide comprehensive information to
                                    document the achievements and to share lessons learned.
                                </p>
                            </div>
                        </div>
                        <div>
                            <img
                                style={{
                                    justifyContent: "center",
                                    display: "flex",
                                }}
                                src="/forest.svg"
                                alt=""
                                className="mx-auto my-4 volunteering"
                            />
                        </div>
                    </Container>
                    {/*<Container*/}
                    {/*    className={'project-header'}*/}
                    {/*    style={{*/}
                    {/*        justifyContent: "flex-end",*/}
                    {/*        alignItems: "center",*/}
                    {/*        display: "flex",*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*        <div className="header-container-tree" style={{ textAlign: "justify" }}>*/}
                    {/*            <p className="subtitle-wine">Registered Projects</p>*/}
                    {/*            <p className="paragraph">*/}
                    {/*                Register local emission reduction projects to make real changes happen in your community. Showcase your success stories, provide comprehensive information to document the achievements and to share lessons learned.*/}
                    {/*            </p>*/}
                    {/*    </div>*/}
                    {/*    <div className={'forest-img'}>*/}
                    {/*        <img*/}
                    {/*            style={{*/}
                    {/*                justifyContent: "center",*/}
                    {/*                display: "flex",*/}
                    {/*            }}*/}
                    {/*            src="/forest.svg"*/}
                    {/*            alt=""*/}
                    {/*            className="mx-auto my-1 hands"*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</Container>*/}
                </div>

                {/* PROJECTS inside ContentBox */}
                <ContentBox title="Projects" width="12">

                    {/* Search Bar */}
                    <div style={{display: "flex", justifyContent: "center", marginBottom: "2rem", width: "100%"}}>
                        <div className={'search-wrapper'}
                             style={{
                                 position: "relative",
                                 width: "70%",
                                 maxWidth: "700px",
                             }}
                        >
                            <input
                                type="text"
                                className="custom-search-input"
                                placeholder="Search..."
                                value={this.state.searchTerm}
                                onChange={(e) => this.setState({searchTerm: e.target.value, currentPage: 1})}
                                style={{
                                    width: "100%",
                                    padding: "10px 40px 10px 40px",
                                    borderRadius: "8px",
                                    border: "1px solid #1D84B5",
                                    fontSize: "16px",
                                    outline: "none",
                                    boxSizing: "border-box",
                                }}
                            />
                            {/* Search icon (left) */}
                            <span
                                style={{
                                    position: "absolute",
                                    left: "12px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    fontSize: "18px",
                                    color: "#1D84B5",
                                }}
                            >
<img
    style={{
        justifyContent: "center",
        display: "flex",
        width: '24px'
    }}
    src="/search.svg"
    alt=""
/>
    </span>
                            {/* Clear button (right) */}
                            {this.state.searchTerm && (
                                <button
                                    onClick={() => this.setState({searchTerm: ""})}
                                    style={{
                                        position: "absolute",
                                        right: "12px",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        background: "transparent",
                                        border: "none",
                                        fontSize: "18px",
                                        cursor: "pointer",
                                        color: "#1D84B5",
                                    }}
                                    aria-label="Clear search"
                                >
                                    ×
                                </button>
                            )}
                        </div>
                    </div>
                    {/*PROJECTS*/}
                    <div style={{width: "100%", alignItems: "center", justifyContent: "center", justifyItems: 'center'}}>
                        <div style={{maxWidth: "890px"}}>
                        <Row>{projectItems}</Row>
                        </div>
                    </div>
                    {/* Pagination Controls */}
                    {filteredProjects.length > projectsPerPage && (
                        <div style={{display: "flex", justifyContent: "center", marginTop: "2rem"}}>
                            <button
                                onClick={() =>
                                    this.setState(prev => ({currentPage: Math.max(prev.currentPage - 1, 1)}))
                                }
                                disabled={currentPage === 1}
                                className="btn btn-secondary mx-2"
                            >
                                Prev
                            </button>
                            <span style={{paddingTop: 8}}>
      Page {currentPage} of {Math.ceil(filteredProjects.length / projectsPerPage)}
    </span>
                            <button
                                onClick={() =>
                                    this.setState(prev => ({
                                        currentPage: Math.min(
                                            prev.currentPage + 1,
                                            Math.ceil(filteredProjects.length / projectsPerPage)
                                        )
                                    }))
                                }
                                disabled={currentPage === Math.ceil(filteredProjects.length / projectsPerPage)}
                                className="btn btn-secondary mx-2"
                            >
                                Next
                            </button>
                        </div>
                    )}
                </ContentBox>
            </>
        );
    }
}

Projects.propTypes = {
    getPublicProjects: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    project: state.project
});

export default connect(
    mapStateToProps,
    {getPublicProjects}
)(Projects);
