let backendHostCore;

//const hostname = window && window.location && window.location.hostname;

// if(hostname === 'realsite.com') {
// backendHost = 'https://api.realsite.com';
// } else if(hostname === 'staging.realsite.com') {
// backendHost = 'https://staging.api.realsite.com';
// } else if(/^qa/.test(hostname)) {
// backendHost = `https://api.${hostname}`;
// } else {
// backendHost = process.env.REACT_APP_BACKEND_HOST || 'http://localhost:8080';
// }

if (process.env.NODE_ENV === "production") {
	// production
	const hostname = window && window.location && window.location.hostname;
	if (hostname === "test.localcarbonregistry.org") {
		backendHostCore = "https://testapi.localcarbonregistry.org";
	} else if (hostname === "dev.localcarbonregistry.org") {
		backendHostCore = "https://dev-api.localcarbonregistry.org";
	} else if (hostname === "preview.localcarbonregistry.org") {
		backendHostCore = "https://preview-api.localcarbonregistry.org";
	} else if (hostname === "d1uksx8jn7r7zx.cloudfront.net") {
		backendHostCore = "https://x9mo4f3ipk.execute-api.ca-central-1.amazonaws.com/Dev/Dev";
	} else if (hostname === "dbf5693oyn557.cloudfront.net") {
		backendHostCore = "https://x9mo4f3ipk.execute-api.ca-central-1.amazonaws.com/Dev/Test";
	} else if (hostname === "d3mmsf1bszud8p.cloudfront.net") {
		backendHostCore = "https://x9mo4f3ipk.execute-api.ca-central-1.amazonaws.com/Dev/Prod";
	} else {
		backendHostCore = "https://api.localcarbonregistry.org";
	}
} else {
	// development (on localhost)
	backendHostCore = "https://localhost:5001";
}

//backendHostCore = "http://localhost:80/dev";
const API_ROOT = ``;
const CORE_API_ROOT = `${backendHostCore}`;

const USER_HOME_PATH = "/myprojects";
const ADMIN_HOME_PATH = "/admin";

export { API_ROOT, CORE_API_ROOT, USER_HOME_PATH, ADMIN_HOME_PATH };
